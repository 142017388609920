@import '../styles/settings.scss';

@keyframes box3 {
    from { transform: translateX(-200%); opacity: 0 }
    to { transform: translateX(0%); opacity: 1 }
 }
 @keyframes box2 {
    from { transform: translateX(-100%); opacity: 0 }
    to { transform: translateX(0%); opacity: 1 }
 }
 
@keyframes box1 {
    from { transform: translateX(-80%); opacity: 0 }
    to { transform: translateX(0%); opacity: 1 }
}

.footer {
    height: 200px;
    background-color: #666;
    align-items: center;
    justify-content: center;
    color: $color-white;
    padding-left: 90px;
    padding-right: 90px;
    @media (max-width: 991.98px) {
        height: auto;
        padding: auto;
        display: block;
    }
    img {
        width: 50px;
        height: 50px;
        margin:auto;
        filter: invert(1);
        padding: 8px;
    }
    .boxMap {
        background-color: $backbround-color-green-dark;
        height: 200px;
        padding-top: 40px;
        &__animated {
            animation: box1;
            animation-duration: 2s;
            animation-timing-function: ease-out;
            z-index: 4;
        }
        @media (max-width: 575.98px) {
            height: auto;
        }
    }
    .boxPhone {
        background-color: $background-color-green;
        height: 200px;
        padding-top: 40px;
        &__animated {
            animation: box2;
            animation-duration: 2s;
            animation-delay: 1s;
            transition-delay: 1s;
            animation-timing-function: ease-out;
            z-index: 3;
        }
        @media (max-width: 575.98px){
            height: auto;
            padding:auto;
        }
    }
    .boxEnvelope {
        background-color: $backbround-color-green-light;
        height: 200px;
        padding-top: 40px;
        &__animated {
            animation: box3;
            animation-duration: 2s;
            animation-delay: 2s;
            transition-delay: 2s;
            animation-timing-function: ease-out;
            z-index: 2;
        }
        @media (max-width: 575.98px){
            height: auto;
            padding:auto;
        }
    }
}

.copy {
    background-color: black;
    color: $color-white;
    display: flex;
    padding: 0 30px;
    align-content: center;
    justify-content: space-between;
    p {
        padding: 15px;
    }
}