@import '../styles/settings.scss';

.home {
    .slideUp {
        height: 750px;
        overflow: hidden;
        position: relative;
        background-image: url(../../public/images/pulse-3530383_1280.avif);
        background-size: cover;
    }
    .info {
        position: absolute;
        display: block;
        text-align: center;
        padding: 25px;
        left: 100px;
        top: 50px;
        width: 400px;
        height: 600px;
        background-color: $background-color-green;
        color: $color-white;
        @media (max-width: 991.98px) {
            left: 8px;
            right: 8px;
            top: 8px;
            width: auto;
            height: auto;
        }
    }
    a {
        margin: 32px auto;
    }
    .infoCall {
        height: 90px;
        background-color: #f0f0f0;
        text-align: center;
        color: $background-color-green;
        padding-top: 25px;
    }
    button {
        margin: 0 0 0 36px;
        background-color: $background-color-green;
        a {
            text-decoration: none;
            color: #f0f0f0;
        }
    }
    .infoCallMain {
        height: 90px;
        background-color: #f0f0f0;
        text-align: left;
        color: $background-color-green;
        padding-top: 25px;
        @media (max-width: 991.98px) {
            height: auto;
            margin: 0;
            padding: 25px;
        }
    }
    .affairs {
        padding: 0 60px 0 60px;
        height: 500px;
        display: flex;
        background-color: $color-white;
        @media (max-width: 991.98px) {
            height: auto;
            padding: 6px;
        }
    }

    .slideBottom {
        background-image: url(../../public/images/image8-min.avif);
        height: 550px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: sticky;
        bottom: 30px;
        z-index: -1;
        @media (max-width: 991.98px) {
            width: 100%;
        }
        .slideInner {
            margin: 250px 28px;
            position: absolute;
            width: 100%;
            text-shadow: 3px 1px #7b8487;
            @media (max-width: 991.98px) {
                margin: 80px 0;
            }
        }    
    }
    .reverse {
        @media (max-width: 991.98px) {
            height: auto;
            flex-direction: column-reverse;
        }
    }
}