@import url(../styles/settings.scss);

.contact {
    margin: 8px;
    .mapDiv {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 600px;
    }
    .hexagon { 
        margin: 20px;
        min-width: 100px;
        min-height: 100px; 
        background-color: #648c66; 
        border-radius: 20%;
        img {
            height: 60px;
        } 
    }
    .puzzle {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .adresColumn {
        transform: translate(0, 25%);
    }
}