@import url(../styles/settings.scss);

.procedure {
    margin: 30px 190px;
    @media (max-width: 768px) {
        margin: 6px;
    }

    h2 {
        margin-bottom: 40px;
        text-align: center;
    }
    p {
        margin-bottom: 36px;
    }
    ul {
        justify-content: left;
    }
    .indented {
        padding-left: 36px;
    }
}