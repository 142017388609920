
.header {
    a {
        text-decoration: none;
        color: black;
    }
    a img {
        margin: 0px;
        width: auto;
        height: 100%;
        background-color: none;
        border-radius: 25px;
    }
    a.bip img {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 0; 
    }
    ul {
        margin: 16px 0px;
        li {
            padding: 0px 24px;
        }
    }
    img {
        width: 40px;
        height: 40px;
        margin: 6px;

    }
    .social {
        @media (max-width: 575.98px){
            text-align: center;
            a img{
                margin: 6px auto;
            }
        }
        img{
            margin: 6px ;
            height: 40px;
            width: auto;
        }
    }
    .nfz {
        @media (max-width: 575.98px){
            text-align: center;
            a img{
                margin: 6px auto;
            }
        }
        img{
            margin: 6px auto auto 0 ;
            height: 40px;
            width: auto;
        }
    }
    .logo {
        width: 200px;
        justify-items: 131px;
    }
 
}