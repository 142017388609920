@import '../styles/settings.scss';

.card {
    position: relative;
    height: 600px;
    background-color: $color-white;
    h3, p {
        padding: 16px 36px;
    }
    ul {
        padding: 8px 48px;
    }
    @media (max-width: 991.98px) {
        height: 100%;
        margin: 0;
        padding: 0;
        h3 {
            padding: 16px;
        }
        p {
            padding: 16px;
            margin-bottom: 0;
        }
    }
    img {
        object-fit: cover;
        overflow: hidden;
        height: 600px;
        width: 100%;
        padding: 0px;
        position: absolute;
        left: 0px;
        top: 0px;
        @media (max-width: 991.98px) {
            object-fit: cover;
            margin: 0;
            padding: 0;
            position: relative;
        }
    }
}

