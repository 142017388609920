@import url(./BenefitsScope.module.scss);

.benefitsScope {
    h2 {
        margin-bottom: 40px;
    }
    p {
        text-align: left;
        padding: 0 60px;
        @media (max-width: 576px ) {
            padding: 0 6px;
        }
    }
    ul{
        text-align: left;
        padding: 0 76px;
        @media (max-width: 576px ) {
            padding: 0 12px;
        }
    }
    margin: 8px;
    img {
        max-width: 500px;
        height: auto;
        @media (max-width: 576px) {
            scale: 0.6;
            transform: translate(-30%, 0%);  
        }
    }
}