@import '../styles/settings.scss';

.slideBottomComponent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: $color-white;
    @media (max-width: 575.98px) {
        margin-top: 8px;
        margin-bottom: 8px;   
    }
    img {
        width: 65px;
        height: 65px;
        display: block;
        margin: auto;
        color: $color-white;
        filter: invert(1);
    }
}